import React from 'react';
import Head from 'next/head';

const SITE_NAME = 'Makeship';
const DEFAULT_DESCRIPTION =
  'We fuel Creator individuality by bringing their content to life through crowdfunding of high-quality, environmentally conscious and limited edition products.';

type PageHeadProps = {
  title: string;
  url?: string;
  description?: string;
  image?: string;
  type?: string;
  price?: string;
  currency?: string;
  hideName?: boolean;
};

const PageHead: React.FC<PageHeadProps> = ({
  title,
  url,
  description,
  image,
  type,
  price,
  currency,
  hideName,
}: PageHeadProps) => (
  <>
    <Head>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>
        {title}
        {!hideName ? ` | ${SITE_NAME}` : ''}
      </title>
      <meta name="description" content={description || DEFAULT_DESCRIPTION} />

      <meta property="og:site_name" content={SITE_NAME} key="ogsitename" />
      <meta property="og:title" content={title} key="ogtitle" />
      {url && <meta property="og:url" content={`${process.env.NEXT_PUBLIC_DOMAIN || ''}${url}`} />}
      <meta property="og:description" content={description || DEFAULT_DESCRIPTION} key="ogdesc" />
      <meta
        property="og:image"
        content={image || `${process.env.NEXT_PUBLIC_DOMAIN || ''}/assets/makeship-logo.jpg`}
        key="ogimage"
      />
      <meta property="og:type" content={type || 'website'} key="ogtype" />
      <meta name="thumbnail" content={image || `${process.env.NEXT_PUBLIC_DOMAIN || ''}/assets/makeship-logo.jpg`} />

      <meta name="twitter:card" content="summary" key="twcard" />
      <meta name="twitter:title" content={title} />
      {url && <meta name="twitter:url" content={`${process.env.NEXT_PUBLIC_DOMAIN || ''}${url}`} />}
      {description && <meta name="twitter:description" content={description || DEFAULT_DESCRIPTION} />}
      <meta
        name="twitter:image"
        content={image || `${process.env.NEXT_PUBLIC_DOMAIN || ''}/assets/makeship-logo.jpg`}
      />

      {price && <meta property="product:price:amount" content={price} />}
      {currency && <meta property="product:price:currency" content={currency} />}
      {title && image && description && url && currency && price && (
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Product',
            name: title,
            image,
            description,
            offers: {
              '@type': 'Offer',
              url,
              priceCurrency: currency,
              price,
            },
          })}
        </script>
      )}
      {/* Temp Pinterest Domain Claim */}
      <meta name="p:domain_verify" content="56aaf4498a735f9f5e9b76911eb0d5a8" />
    </Head>
  </>
);

export default PageHead;
